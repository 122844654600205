import React, { useState, useRef, useEffect, ReactNode } from "react";

const Text = ({ size, color, weight, align, font, children }: {
  size: string | number,
  color?: string,
  weight?: number,
  align?: CanvasTextAlign,
  font?: string
  children: ReactNode,
}) => {
 
  return (
    <div style={{
      textAlign: align ? align : "left",
      fontFamily: font ? font : "Assistant",
      fontSize: size + "px",
      fontStyle: "normal",
      fontWeight: weight ? weight : 400,
      color: color ? color : "black",
    }}>
      {children}
    </div>
  )
};

export default Text;
