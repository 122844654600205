import React, { ReactNode, useCallback, useState } from 'react';
import { useCookies } from "react-cookie";

import './FileDrop.css';
import { s3Presign, updateDocument, uploadDocument } from '../requests';

interface PresignData {
  url: string;
  fields: {
    [key: string]: string | Blob; // Specifying that fields can be string or Blob
  };
}

const FileDrop = ({ documentType, setErrorMessage, retrieveDocuments, documentsLength, category, clientId, associatedDocumentId, children }: {
  documentType: string,
  setErrorMessage: (s: string) => void,
  retrieveDocuments: () => void,
  documentsLength: number,
  category: string,
  clientId: string,
  associatedDocumentId?: string
  children: ReactNode
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [dragOver, setDragOver] = useState(false);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    if (!dragOver) setDragOver(true);
  }, []);

  const onFileUpload = async (file) => {
      try {
        const fileKey = `${Date.now()}_${file.name}`;
        const resp = await uploadDocument(cookies['user-id'], fileKey, category, clientId, associatedDocumentId, file.name);
        await retrieveDocuments();

        // Get the pre-signed URL from the Flask backend
        const presignResponse = await s3Presign(cookies['user-id'], fileKey);
        const presignData: PresignData = presignResponse.body["url"];

        // Prepare the form data for posting the PDF file
        const formData = new FormData();
        Object.entries(presignData.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append('file', file);

        // Post the file to the S3 bucket using the pre-signed URL
        const uploadResponse = await fetch(presignData.url, {
          method: 'POST',
          body: formData
        });

        if (uploadResponse.ok) {
          await updateDocument(cookies['user-id'], resp.body['document_instance_id'], "uploaded", "", fileKey);
        } else {
          throw new Error('Upload failed');
        }
      } catch (err) {
        console.error('Error uploading file:', err);
        setErrorMessage("Unable to upload file at this time. Please try in a minute.")
      }

  }

  // const onFileUpload = async (file) => {
  //   if (file.type !== 'application/pdf') {
  //     setErrorMessage('Error: Only PDF files are allowed.');
  //     return;
  //   }

  //   const resp = await uploadDocument(cookies['user-id'], file.name, category, clientId, associatedDocumentId);
  //   console.log("RESP BODY");
  //   console.log(resp.body);
  //   await retrieveDocuments();
  //   // Use the file name as the key for the object in S3
  //   const key = `${Date.now()}_${file.name}`;

  //   const uploadParams = {
  //     Bucket: 'coverflow-docs',
  //     Key: key,
  //     Body: file,
  //     ContentType: 'application/pdf'
  //   };

  //   try {
  //     const data = await s3.upload(uploadParams).promise();
  //     console.log('Upload Success', data.Location);
  //     await updateDocument(cookies['user-id'], resp.body['document_instance_id'], "uploaded", data.Location, key);
  //     // You can use the returned data.location as the URL for the uploaded file
  //   } catch (err) {
  //     console.log('Upload Error', err);
  //   }
  // }

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileUpload(e.dataTransfer.files[0]); // Assuming single file upload, adjust if necessary
      e.dataTransfer.clearData();
    }
  }, [onFileUpload]);

  return (
    <div
      className={`${documentsLength == 0 ? 'drop-zone' : ''} ${dragOver ? 'drag-over' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        minHeight: "375px",
        borderRadius: "8px"
      }}
    >
      {children}
    </div>
  );
};

export default FileDrop;
