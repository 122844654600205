import React, { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import Text from "./base/Text";
import Button from "./base/Button";
import Space from "./base/Space";
import { analyzePolicy, autofillDocument, getPresignedUrl, retryReportRun } from "../requests";
import { secondsSince } from "../utils";

export enum DocumentStatus {
  FAILED = "failed",
  BLANK = "blank",
  UPDATING = "updating",
  UPLOADING = "uploading",
  INCOMPLETE = "incomplete",
  COMPLETE = "complete",
  EXTRACTING = "extracting",
  PARSING = "parsing",
  UPLOADED = "uploaded",
  GENERATED = "generated",
  ANALYZING = "analyzing",
}

export type DocumentType = {
  id: string;
  category: string;
  instanceId?: string;
  associatedDocumentId?: string;
  name: string;
  displayName: string;
  link: string;
  status: DocumentStatus;
  percentComplete: number;
  lines?: string[];
  propertyId?: string;
  property?: string;
  propertyRequired?: boolean;

  // report fields
  instanceIds?: string[];
  reportStatus?: DocumentStatus; // status
  reportLink?: string; // report link in s3
  reportInitiated?: string; // so i can display (3m remaining)
  isReport?: boolean;
}

export type ReportRunType = {
  runId: string;
  instanceIds: string[];
  status: DocumentStatus;
  reportLocation: string;
}

function getWidth(reportInitiatedTime) {
  const now = new Date();
  const initiatedTime = new Date(reportInitiatedTime);
  const elapsedTime = (now.getTime() - initiatedTime.getTime()) / (1000 * 60); // Time elapsed in minutes

  if (elapsedTime >= 10) {
      return 102;
  } else if (elapsedTime <= 0) {
      return 0;
  } else {
      return (elapsedTime / 10) * 102;
  }
}

const Document = ({ d, selecting, selected, retrieveDocuments, onClick }: { d: DocumentType, selecting?: boolean, selected?: boolean, retrieveDocuments?: () => void, onClick?: (d: DocumentType) => void }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [periods, setPeriods] = useState('');
  const [displayName, setDisplayName] = useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [reportInitiated, setReportInitiated] = useState<number | null>(null);
  useEffect(() => {
    if (d.status === DocumentStatus.UPDATING || d.status === DocumentStatus.UPLOADING || d.status === DocumentStatus.EXTRACTING || d.status === DocumentStatus.PARSING || d.status === DocumentStatus.ANALYZING) {
      const interval = setInterval(() => {
        setPeriods((prevPeriods) => (prevPeriods.length < 3 ? prevPeriods + '.' : ''));
      }, 500);
  
      return () => clearInterval(interval);
    }
  }, [d.status]);

  const onAutoFill = async () => {
    await autofillDocument(cookies['user-id'], d.instanceId ?? "");
    retrieveDocuments && retrieveDocuments();
  }

  const handleDownload = async () => {
    // Fetch the PDF from the URL
    const resp = await getPresignedUrl(cookies['user-id'], d.instanceId ?? "", (d.category == "comparison" || d.category == "analysis"));

    const response = await fetch(resp.body["url"]);
    if (!response.ok) throw new Error('Failed to fetch PDF');
    
    // Create a Blob from the PDF stream
    const blob = await response.blob();
    // Create a local URL for the Blob
    const url = window.URL.createObjectURL(blob);
    
    // Create a temporary anchor element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${d.name}.pdf`; // Optional: specify a filename for the downloaded file
    document.body.appendChild(a); // Append to the document
    a.click(); // Trigger the download
    
    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const onGenerate = async () => {
    await analyzePolicy(cookies['user-id'], "", d.instanceId ?? "", "external");
    retrieveDocuments && retrieveDocuments();
  }

  const onRetry = async () => {
    console.log("HERE1");
    await retryReportRun(cookies['user-id'], d.instanceId ?? "");
    console.log("HERE");
    retrieveDocuments && retrieveDocuments();
    console.log("RETRIEVED");
  }

  /*
  <Text size={14} align="center">
                {5 - Math.floor(d.percentComplete / 20) > 0 ? `${5 - Math.floor(d.percentComplete / 20)} min remaining` : `Finalizing`}{periods}
              </Text>
  */

  useEffect(() => {

    if (d.name.endsWith("- Report")) {
      if (d.name.replace(" - Report", "").length > 17) {
        setDisplayName(
          d.name.replace(" - Report", "").slice(0, 17) + "... - Report"
        );
      } else {
        setDisplayName(
          d.name
        );
      }

    } else if (d.name.endsWith("- Comparison")) {
      if (d.name.replace(" - Comparison", "").length > 17) {
        setDisplayName(
          d.name.replace(" - Comparison", "").slice(0, 17) + "... - Comparison"
        );
      } else {
        setDisplayName(
          d.name
        );
      }
    } else {
      if (d.name.length > 17) {
        setDisplayName(
          d.name.slice(0, 17) + "..."
        );
      } else {
        setDisplayName(
          d.name
        );
      }
    }

  }, [d])

  useEffect(() => {
    if (d.status == DocumentStatus.ANALYZING && reportInitiated === null) {
      const now = new Date();
      setReportInitiated(now.getTime());
    }

  }, [d.status])

  useEffect(() => {
    console.log("REPORT INITIATED");
    console.log(reportInitiated);
    if (reportInitiated) {
        const interval = setInterval(() => {
            setWidth(getWidth(reportInitiated));
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }
  }, [reportInitiated]);

  /*

              <div style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
              <div style={{ position: "relative", marginRight: "10px" }}>
                <div style={{ position: "absolute", top: 0, left: -50, width: "100px", height: "8px", borderRadius: "8px", backgroundColor: "#ffffff", border: "2px solid #000000" }}></div>
                <div style={{ position: "absolute", top: 2, left: -49, width: `${width}px`, height: "8px", borderRadius: "4px", backgroundColor: "#000000" }} /> 
              </div>
            </div>
  */

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "175px",
      gap: "5px",
      borderRadius: "16px",
      border: !!selected ? "2px solid #000000" : undefined,
      marginRight: selected ? "-4px" : undefined,
      marginBottom: selected ? "-4px" : undefined,
    }}>
      { selecting && (
        <Space px={20} />
      )}
      <img style={{ width: "71px", height: "71px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} onClick={() => !selecting && onClick && onClick(d)} />
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        justifyContent: "center"
      }}>
        <div style={{ cursor: "pointer" }} onClick={() => !selecting && onClick && onClick(d)}>
          <Text size={20} align="center">{displayName}</Text>
        </div>
        {!selecting && <img
          style={{ width: "23px", height: "23px", cursor: "pointer" }}
          src={ process.env.PUBLIC_URL + "/assets/download.png"}
          onClick={(d.status !== DocumentStatus.ANALYZING) ? () => handleDownload() : undefined}
        />}
      </div>
      {!selecting && (
        <>
          { d.property && (
            <>
              <Button height="20px" width="100px" borderRadius="16px" color="#ffffff" border="1px solid #000000">
                <Text size={12} color="#000000">
                  {d.property}
                </Text>
              </Button>
              <Space px={5} />
            </>
          )}
          { (d.status !== DocumentStatus.BLANK && d.status !== DocumentStatus.UPLOADING && d.status !== DocumentStatus.UPLOADED && d.status !== DocumentStatus.EXTRACTING && d.status !== DocumentStatus.PARSING && d.status !== DocumentStatus.ANALYZING && d.status !== DocumentStatus.GENERATED) && (
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: 0, left: -50, width: "100px", height: "8px", borderRadius: "8px", backgroundColor: "#ffffff", border: "2px solid #000000" }}></div>
              <div style={{ position: "absolute", top: 2, left: -48, width: (false ? 100 : d.percentComplete) + "px", height: "8px", borderRadius: "8px", backgroundColor: "#000000" }} /> 
            </div>
          )}
          { d.status === DocumentStatus.BLANK && (
            <Button width="70px" height="35px" color="#ffffff" border="1px solid black" onClick={onAutoFill}>
              <Text size={16} color="#000000">
                Auto-fill
              </Text>
            </Button>
          )}
          { d.status === DocumentStatus.UPDATING && (
            <>
              <Space px={7} />
              <Text size={14} align="center">
                Updating{periods}
              </Text>
            </>
          )}
          { d.status === DocumentStatus.PARSING && (
            <>
              <Text size={14} align="center">
                Parsing{periods}
              </Text>
            </>
          )}
          { d.status === DocumentStatus.UPLOADING && (
            <>
              <Text size={14} align="center">
                Uploading{periods}
              </Text>
            </>
          )}
          { d.status === DocumentStatus.FAILED && (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", marginTop: "10px"}}>
              <Text size={14} align="center" color="red">
                Failed
              </Text>
              <img
                  style={{ width: "14px", height: "14px", cursor: "pointer" }}
                  src={process.env.PUBLIC_URL + `/assets/refresh.png`}
                  onClick={onRetry}
                />
            </div>
          )}
          { d.status === DocumentStatus.ANALYZING && (
            <>
             <div style={{ position: "relative", marginRight: "10px" }}>
              <div style={{ position: "absolute", top: 0, left: -50, width: "100px", height: "8px", borderRadius: "8px", backgroundColor: "#ffffff", border: "2px solid #000000" }}></div>
              <div style={{ position: "absolute", top: 0, left: -50, width: `${width}px`, height: "11px", borderRadius: "8px", backgroundColor: "#000000" }} /> 
            </div>
            <Space px={5} />
            <div style={{ marginRight: "10px" }}>
              <Text size={14} align="center">
                {width < 100 ? "Generating" : "Finalizing"}{periods}
              </Text>
            </div>
            </>
          )}
          { (d.status === DocumentStatus.INCOMPLETE || d.status === DocumentStatus.COMPLETE) && (
            <>
              <Space px={7} />
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px", cursor: "pointer" }}>
                <Text size={14} align="center">
                  {d.percentComplete}% complete
                </Text>
                <div>
                  <img
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Click to re-fill the form with current data"
                    style={{ width: "14px", height: "14px", cursor: d.percentComplete < 100 ? "pointer" : undefined }}
                    src={process.env.PUBLIC_URL + `/assets/${d.percentComplete < 100 ? "refresh" : "complete"}.png`}
                    onClick={onAutoFill}
                  />
                  <Tooltip id="my-tooltip" place="bottom" style={{ fontFamily: "Assistant" }} />
                </div>
              </div>
            </>
          )}
          { (d.status === DocumentStatus.UPLOADED) && (
            <>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px", cursor: "pointer" }}>
                <Text size={14} align="center">
                  Analyzed
                </Text>
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={process.env.PUBLIC_URL + `/assets/complete.png`}
                />
              </div>
            </>
          )}
          { (d.status === DocumentStatus.GENERATED) && (
            <div style={{ marginTop: "0px" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px", cursor: "pointer" }}>
                <Text size={14} align="center">
                  Generated
                </Text>
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={process.env.PUBLIC_URL + `/assets/complete.png`}
                />
              </div>
            </div>
          )}
        </>
      )}
      { selecting && (
        <Space px={20} />
      )}
    </div>
  )
};

export default Document;

