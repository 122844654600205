import React from 'react';

import Text from './base/Text';
import SidebarOption from './SidebarOption';

const Sidebar = ({ page, setPage }: { page: string, setPage: (s: string) => void }) => {

  /*
            <SidebarOption text="Basic Information" isPage={page === "basicInformation"} page="basicInformation" setPage={setPage} />
          <SidebarOption text="Communications" isPage={page === "communications"} page="communications" setPage={setPage} />
                    <SidebarOption text="Client Information" isPage={page === "extractedData"} page="extractedData" setPage={setPage} />
          <SidebarOption text="Alerts" isPage={page === "alerts"} page="alerts" setPage={setPage} />
                    <SidebarOption text="Definitions" isPage={page === "definitions"} page="definitions" setPage={setPage} />
                              <SidebarOption text="Overview" isPage={page === "overview"} page="overview" setPage={setPage} />

  */
  return (
    (
      <div style={{
        position: "fixed",
        top: "0px",
        left: "0px",
        backgroundColor: "#F7F7F7",
        width: "206px",
        height: "100%"
      }}>
        <div style={{ marginTop: "131px" }}>
          <SidebarOption text="Documents" isPage={page === "documents"} page="documents" setPage={setPage} />
        </div>
      </div>
    )
  );
};

export default Sidebar;
