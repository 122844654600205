import React, { useState, useRef, useEffect, ReactNode } from "react";
import Switch from '@mui/material/Switch';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentStatus, DocumentType } from "./Document";
import Button from "./base/Button";
import { analyzePolicy, comparePolicyCoverage, deleteDocument, getPresignedUrl, setDisplayName, updateCoverageLines } from "../requests";
import { useCookies } from "react-cookie";
import AISearch from "./AISearch";
import LoadingSpinner from "./base/Spinner";
import Space from "./base/Space";
import './base/Button.css';
import FileDrop from "./FileDrop";
import NewDocument from "./NewDocument";
import Container from "./Container";

// test update
const coverageLines = [
  "Cyber",
  "D&O",
  "Professional Liability",
  "Commercial Property",
  "General Liability",
  "BOP",
  "Garage",
  "EPL",
  "Crime",
  "Pollution Liability",
  "Logging",
  "Builder's Risk",
  "Commercial Auto",
  "Inland Marine",
  "Umbrella"
]


const PDFModal = ({ setModalOpen, modalOpen, document, documents, setDocument, retrieveDocuments, setSuccessMessage, setErrorMessage, secondDocument, setSecondDocument }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  document: DocumentType | null,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  setDocument: (d: DocumentType | null) => void,
  secondDocument: DocumentType | null,
  setSecondDocument: (d: DocumentType | null) => void,
  setSuccessMessage: (s: string) => void,
  setErrorMessage: (s: string) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [page, setPage] = useState<string>("normal");
  const [policyInfo, setPolicyInfo] = useState<string>("");
  const [generationStarted, setGenerationStarted] = useState<string | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [deleteButtonClicked, setDeleteButtonClicked] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [selectedLines, setSelectedLines] = useState<string[]>([]);
  const [objective, setObjective] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string>("");
  const [localDisplayName, setLocalDisplayName] = useState<string>("");
  const [endorsements, setEndorsements] = useState<DocumentType[]>([]);
  const [schedules, setSchedules] = useState<DocumentType[]>([]);
  const [markdownReport, setMarkdownReport] = useState<boolean>(false);
  const [additionalDocument, setAdditionalDocument] = useState<DocumentType | null>(null);
  const [additionalDocumentUrl, setAdditionalDocumentUrl] = useState<string>("");

  const onGenerate = async (reportType: string) => {
    setGenerationStarted(reportType);
    if (reportType != "comparison") {
      const policyResp = await analyzePolicy(cookies['user-id'], policyInfo, document?.instanceId ?? "", reportType ?? "", markdownReport);
      if (policyResp.body["bad_policy"]) {
        setErrorMessage("We are unable to generate reports for this coverage line. We apologize for this inconvenience.");
        setGenerationStarted(null);
      }
    } else {
      await comparePolicyCoverage(cookies['user-id'], [document?.instanceId ?? "", secondDocument?.instanceId ?? ""], markdownReport);
    }
    
    retrieveDocuments();
    setModalOpen(false);
    setDeleteButtonClicked(false);
    setPage("normal");
    setPresignedUrl("");
    setGenerationStarted(null);
  }

  const deleteDoc = async () => {
    setDeleting(true);
    const resp = await deleteDocument(cookies['user-id'], document?.instanceId ?? "", document?.category != "policy");
    if (resp.status == 200) {
      setSuccessMessage("Document deleted successfully.");
      retrieveDocuments();
      setModalOpen(false);
      setDeleteButtonClicked(false);
      setPage("normal");
      setPresignedUrl("");
      setGenerationStarted(null);
    } else {
      setErrorMessage("Unable to delete the document at this time. Please try again in a minute.")
    }

    setDeleting(false);
  }

  const deleteAdditionalDoc = async () => {
    setDeleting(true);
    const resp = await deleteDocument(cookies['user-id'], additionalDocument?.instanceId ?? "", false);
    if (resp.status == 200) {
      setSuccessMessage("Document deleted successfully.");
      retrieveDocuments();
      setDeleteButtonClicked(false);
      setPage("additionalDocuments");
    } else {
      setErrorMessage("Unable to delete the document at this time. Please try again in a minute.")
    }

    setDeleting(false);
  }

  const saveDisplayName = async () => {
    const resp = await setDisplayName(cookies['user-id'], document?.instanceId ?? "", editedName, document?.category != "policy");
    if (resp.status == 200) {
      retrieveDocuments();
      setLocalDisplayName(editedName);
      setEditingName(false);
    } else {
      setErrorMessage("Unable to edit document name at this time. Please try again in a minute.")
    }
    
  }

  const onViewAdditionalDocument = async (d: DocumentType) => {
    const resp = await getPresignedUrl(cookies['user-id'], d?.instanceId ?? "", false);
    setAdditionalDocument(d);
    setAdditionalDocumentUrl(resp.body["url"]);
    setPage("viewAdditionalDocument");
  }

  useEffect(() => {
    let ends: DocumentType[] = [];
    let schds: DocumentType[] = [];
    documents.filter(d => d.associatedDocumentId == document?.instanceId).forEach(d => {
      if (d.category == "endorsement") ends.push(d);
      else if (d.category == "schedule") schds.push(d);
    })

    setEndorsements(ends);
    setSchedules(schds);
  }, [document, documents])

  useEffect(() => {
    setLocalDisplayName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    setEditedName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    let intervalId;
  
    async function retrievePresignedUrl() {
      if (modalOpen) {
        try {
          const resp = await getPresignedUrl(cookies['user-id'], document?.instanceId ?? "", (document?.category == "comparison" || document?.category == "analysis"));
          setPresignedUrl(resp.body["url"]);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }
  
    retrievePresignedUrl(); // Initial call
  
    if (modalOpen) {
      intervalId = setInterval(retrievePresignedUrl, 59 * 60 * 1000); // Set interval for 59 minutes
    }
  
    return () => {
      clearInterval(intervalId); // Clear interval on component unmount or dependencies change
    };
  
  }, [document, document?.instanceId, document?.category, modalOpen]);

  useEffect(() => {
    setSelectedLines(document?.lines ?? []);
  }, [document, document?.lines, modalOpen])

  const onLineClick = (l: string) => {
    if (!!selectedLines.find(l2 => l2 == l)) {
      setSelectedLines(selectedLines.filter(l2 => l2 != l));
    } else {
      setSelectedLines(prev => [...prev, l]);
    }
  }

  const onConfirmLines1 = async () => {
    setLoading(true);
    await updateCoverageLines(cookies['user-id'], document?.instanceId ?? "", selectedLines);
    if (objective == "report") {
      await onGenerate("internal");
    } else {
      setSelectedLines(selectedDocument?.lines ?? []);
      setPage("confirmLines2");
    }
    setLoading(false);
  }

  const onConfirmLines2 = async () => {
    setLoading(true);
    await updateCoverageLines(cookies['user-id'], secondDocument?.instanceId ?? "", selectedLines);
    await onGenerate("comparison");
    setLoading(false);
  }

  const onDocumentClick = (d2: DocumentType) => {
    setSelectedDocument(d2);
  }
  
  const onCompareSelect = async () => {
    setSecondDocument(selectedDocument);
    setPage("compare");
  }

  /*
<Container title="Schedules" width="40%">
                <FileDrop documentType="schedules" documentsLength={schedules.length} retrieveDocuments={retrieveDocuments} category="schedule" clientId={"default_client"} setErrorMessage={setErrorMessage} associatedDocumentId={document?.instanceId}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px", flexWrap: "wrap", width: "100%", justifyContent: schedules.length == 0 ? "center" : undefined }}>
                    { schedules.map(d => (
                      <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                        onViewAdditionalDocument(d);
                      }} />
                    ))}
                    <NewDocument documentsLength={schedules.length} retrieveDocuments={retrieveDocuments} category="schedule" clientId={"default_client"} associatedDocumentId={document?.instanceId} />
                  </div>
                </FileDrop>
              </Container>
  */

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="pdfModal" onModalClose={() => {
      setPage("normal");
      setDeleteButtonClicked(false);
    }} extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "80%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      position: "relative"
    }}>
      { page == "normal" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: 'center',
              justifyContent: "flex-start",
              width: "90%",
              gap: "20px"
            }}>
              { !editingName && (
                <div style={{ maxWidth: "50%" }}>
                  <Text size={35} weight={600}>
                    {localDisplayName}
                  </Text>
                </div>
              )}
              { editingName && (
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.currentTarget.value)}
                  style={{
                    width: "90%",
                    borderRadius: "8px",
                    fontSize: "35px",
                    padding: "10px",
                    border: "1px solid #475569",
                    outline: "none",
                    fontFamily: "Assistant, sans-serif",
                  }}
                />
              )}
              { !editingName && (
                <img src={process.env.PUBLIC_URL + "/assets/edit.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => setEditingName(true)} />
              )}
              { editingName && (
                <>
                  <img src={process.env.PUBLIC_URL + "/assets/cancel.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => {
                    setEditedName(document?.name ?? "");
                    setEditingName(false);
                  }} />
                  <img src={process.env.PUBLIC_URL + "/assets/green-check.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={saveDisplayName} />
                </>
              )}
              { document?.category == "policy" && (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  { generationStarted != "internal" && (
                    <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => {
                      setObjective("report");
                      setPage("confirmLines1");
                    }}> 
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px"
                      }}>
                        <img src={process.env.PUBLIC_URL + "/assets/ai.png"} style={{ width: "20px", height: "20px" }} />
                        <Text size={16}>
                          Generate Report
                        </Text>
                      </div>
                    </Button>
                  )}
                  { generationStarted == "internal" && (
                    <>
                      <Space px={20} />
                      <div style={{ display: "inline-block", transform: "scale(0.6)" }}><LoadingSpinner /></div>
                      <Space px={20} />
                    </>
                  )}
                  <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => setPage("compare")}> 
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: 'center',
                    }}>
                      <img src={process.env.PUBLIC_URL + "/assets/compare.png"} style={{ width: "20px", height: "20px" }} />
                      <Text size={16}>
                        Compare Policy
                      </Text>
                    </div>
                  </Button>
                  <div style={{ display: "flex", flexDirection: "column", gap: "0px", alignItems: "center", justifyContent: "center"}}>
                    <Switch
                      checked={markdownReport}
                      onChange={(event) => setMarkdownReport(event.currentTarget.checked)}
                    />
                    <Text size={12}>
                      Text Report
                    </Text>
                  </div>
                  
                </div>
              )}
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px"
            }}>
              { document?.category == "policy" && (
                <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setPage("additionalDocuments")}> 
                  <Text size={16}>
                    Upload Endorsements
                  </Text>
                </Button>
              )}
              { !deleteButtonClicked && (
                <Button padding="10px" maxHeight="20px" color="white" border="1px solid red" onClick={() => setDeleteButtonClicked(true)}> 
                  <Text size={16} color="red">
                    Delete Document
                  </Text>
                </Button>
              )}
              { deleteButtonClicked && (
                <>
                  <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setDeleteButtonClicked(false)}> 
                    <Text size={16} color="black">
                      Cancel
                    </Text>
                  </Button>
                  { !deleting && (
                    <Button padding="10px" maxHeight="20px" color="red" onClick={deleteDoc}> 
                      <Text size={16} color="white">
                        Delete Document
                      </Text>
                    </Button>
                  )}
                  { deleting && (
                    <LoadingSpinner />
                  )}
                </>
              )}
            </div>
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            height: "80%",
          }}>
            <iframe
              src={presignedUrl}
              width="100%"
              height="110%"
            />
            { document?.instanceIds === undefined && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                height: "110%",
                overflowY: "scroll"
              }}>
                <Text size={30} weight={600}>
                  Associated Reports
                </Text>
                { documents.filter(d => d.instanceIds?.includes(document?.instanceId ?? "")).map(d => (
                  <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                    setDocument(d);
                  }} />
                ))}
              </div>
            )}
          </div>
          
        </>
      )}
      { page == "compare" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select a policy to compare with <div style={{ color: "#354E7F", display: "inline" }}>{localDisplayName}</div>.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "280px",
              height: "280px",
            }}>
              <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
              <Text size={30} weight={600} align="center">
                {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
              </Text>
            </div>
            <Text size={14} color="gray">
              and
            </Text>
            { !secondDocument && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                width: "280px",
                height: "280px",
                border: "1px solid black",
                cursor: "pointer"
              }} onClick={() => setPage("selecting")}>
                <img style={{ width: "75px", height: "75px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
              </div>
            )}
            { secondDocument && (
              <div style={{
                position: "relative"
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "280px",
                  height: "280px",
                }}>
                  <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                  <Text size={30} weight={600} align="center">
                    {secondDocument?.name && secondDocument?.name.length > 17 ? secondDocument?.name.slice(0, 17) + "..." : secondDocument?.name}
                  </Text>
                </div>
                <img style={{ width: "50px", height: "50px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => setSecondDocument(null)} />
              </div>
            )}
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("normal")}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button width="200px" height="55px" greyed={!secondDocument} onClick={() => {
                  setObjective("comparison");
                  setPage("confirmLines1");
                }}>
                  <Text size={20} weight={600} color="white">
                    Next
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "selecting" && (
          <>
            
            <Text size={35} weight={600}>
              Select a policy to compare
            </Text>
            <Divider />
            <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%" }}>
              { documents?.filter(d => d.category == "policy" && d.instanceId != document?.instanceId).map(d => (
                <div style={{ cursor: "pointer" }} onClick={() => onDocumentClick(d)}>
                  <Document d={d} selecting selected={selectedDocument?.instanceId == d.instanceId} />
                </div>
              ))}
            </div>
            <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", gap: "20px" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare")}>
                <Text size={20} weight={600} color="#000000">
                  Cancel
                </Text>
              </Button>
              <Button greyed={!selectedDocument} onClick={onCompareSelect}>
                <Text size={16} weight={600} color="white">
                  Select Document
                </Text>
              </Button>
            </div>
          </>
      )}
      { page == "confirmLines1" && (
          <>
            <Text size={35} weight={600}>
              Confirm the coverage lines for <div style={{ color: "#354E7F", display: "inline" }}>{localDisplayName}</div>
            </Text>
            <Text size={15} color="gray">
              It's not required, but it will make your report significantly more accurate.
            </Text>
            <Divider />
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "32px", flexWrap: "wrap", width: "90%", margin: "auto", transform: "translateY(-10px)" }}>
              { coverageLines.map(l => (
                <div
                  className="button"
                  style={{
                    padding: "18px",
                    borderRadius: "60px",
                    cursor: "pointer",
                    backgroundColor: !!selectedLines.find(l2 => l == l2) ? "#008000" : "#A9A9A9"
                  }}
                  onClick={() => onLineClick(l)}
                >
                  <Text size={28} color="white" weight={600}>{l}</Text>
                </div>
              ))}
            </div>
            <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
                if (objective == "report") {
                  setPage("normal");
                } else {
                  setPage("compare");
                }
              }}>
                <Text size={20} weight={600} color="#000000">
                  Back
                </Text>
              </Button>
              { !loading && (
                <Button onClick={onConfirmLines1}>
                  <Text size={16} weight={600} color="white">
                    { objective == "report" ? "Generate" : "Confirm Lines" }
                  </Text>
                </Button>
              )}
              { loading && (
                <LoadingSpinner />
              )}
            </div>
          </>
      )}
      { page == "confirmLines2" && (
          <>
            <Text size={35} weight={600}>
              Confirm the coverage lines for <div style={{ color: "#354E7F", display: "inline" }}>{secondDocument?.name}</div>
            </Text>
            <Text size={15} color="gray">
              It's not required, but it will make your report significantly more accurate.
            </Text>
            <Divider />
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "32px", flexWrap: "wrap", width: "90%", margin: "auto", transform: "translateY(-10px)" }}>
              { coverageLines.map(l => (
                <div
                  className="button"
                  style={{
                    padding: "18px",
                    borderRadius: "60px",
                    cursor: "pointer",
                    backgroundColor: !!selectedLines.find(l2 => l == l2) ? "#008000" : "#A9A9A9"
                  }}
                  onClick={() => onLineClick(l)}
                >
                  <Text size={28} color="white" weight={600}>{l}</Text>
                </div>
              ))}
            </div>
            <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
              <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
                setPage("compare");
              }}>
                <Text size={20} weight={600} color="#000000">
                  Back
                </Text>
              </Button>
              { !loading && (
                <Button onClick={onConfirmLines2}>
                  <Text size={16} weight={600} color="white">
                    Generate
                  </Text>
                </Button>
              )}
              { loading && (
                <LoadingSpinner />
              )}
            </div>
          </>
      )}
      { page == "additionalDocuments" && (
          <div style={{ width: "100%"}}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}>
              <img style={{ width: "80px", height: "80px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/left-arrow.png"} onClick={() => setPage("normal")} />
              <Text size={35} weight={600}>
                Upload Additional Documents
              </Text>
            </div>
            <Divider />
            <Space px={20} />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "100%", height: "100%", justifyContent: "center" }}>
              <Container title="Endorsements" width="90%">
                <FileDrop documentType="endorsements" documentsLength={endorsements.length} retrieveDocuments={retrieveDocuments} category="endorsement" clientId={"default_client"} setErrorMessage={setErrorMessage} associatedDocumentId={document?.instanceId}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px", flexWrap: "wrap", width: "100%", justifyContent: endorsements.length == 0 ? "center" : undefined }}>
                    { endorsements.map(d => (
                      <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                        onViewAdditionalDocument(d);
                      }} />
                    ))}
                    <NewDocument documentsLength={endorsements.length} retrieveDocuments={retrieveDocuments} category="endorsement" clientId={"default_client"} associatedDocumentId={document?.instanceId} />
                  </div>
                </FileDrop>
              </Container>
            </div>
          </div>
      )}
      { page == "viewAdditionalDocument" && (
          <>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}>
              <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
              }}>
                <img style={{ width: "80px", height: "80px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/left-arrow.png"} onClick={() => setPage("additionalDocuments")} />
                <Text size={35} weight={600}>
                  {additionalDocument?.name ?? ""}
                </Text>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
              }}>
                { !deleteButtonClicked && (
                  <Button padding="10px" maxHeight="20px" color="white" border="1px solid red" onClick={() => setDeleteButtonClicked(true)}> 
                    <Text size={16} color="red">
                      Delete Document
                    </Text>
                  </Button>
                )}
                { deleteButtonClicked && (
                  <>
                    <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setDeleteButtonClicked(false)}> 
                      <Text size={16} color="black">
                        Cancel
                      </Text>
                    </Button>
                    { !deleting && (
                      <Button padding="10px" maxHeight="20px" color="red" onClick={deleteAdditionalDoc}> 
                        <Text size={16} color="white">
                          Delete Document
                        </Text>
                      </Button>
                    )}
                    { deleting && (
                      <LoadingSpinner />
                    )}
                  </>
                )}
              </div>
            </div>
            <Divider />
            <iframe
              src={additionalDocumentUrl}
              width="100%"
              height="110%"
            />
          </>
      )}
    </Modal>
  )
};

export default PDFModal;



/*
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "45%",
                justifyContent: "center",
                alignContent: "center",
                padding: "10px",
                border: "2px solid black",
                borderRadius: "16px"
              }}>
                <Text size={30} weight={600} align="center">
                  Endorsements
                </Text>
                */