import React, { useState } from 'react';
import Text from './base/Text';

const SidebarOption = ({ text, isPage, page, setPage }: { text: string, isPage: boolean, page: string, setPage: (s: string) => void }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  
  return (
    (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "40px",
          cursor: "pointer",
          borderRadius: "8px",
          transition: "background-color 0.25s",
          backgroundColor: isPage ? "#E7E7E7" : (isHovered ? "#ECECEC" : undefined)
        }}
        onClick={() => setPage(page)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div style={{
          marginLeft: "20px"
        }}>
          <Text size={20} weight={isPage ? 700 : 400} color={isPage ? "#354E7F" : undefined}>
            {text}
          </Text>
        </div>

      </div>
    )
  );
};

export default SidebarOption;
