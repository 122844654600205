import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Button from "./base/Button";
import { analyzePolicy, changePassword, logoutUser, retrieveCredits } from "../requests";
import { useCookies } from "react-cookie";
import Space from "./base/Space";
import LoadingSpinner from "./base/Spinner";

const SettingsModal = ({ setModalOpen, modalOpen, setSuccessMessage, setErrorMessage }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  setSuccessMessage: (s: string) => void,
  setErrorMessage: (s: string) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const navigate = useNavigate();

  const [credits, setCredits] = useState<number>(0);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword1, setNewPassword1] = useState<string>("");
  const [newPassword2, setNewPassword2] = useState<string>("");
  const [loadingNewPassword, setLoadingNewPassword] = useState<boolean>(false);
  const [internalError, setInternalError] = useState<string>("");

  useEffect(() => {
    async function refreshCredits() {
      const resp = await retrieveCredits(cookies['user-id']);
      if (resp.status == 200) {
        setCredits(resp.body["credits"]);
      }
    }

    refreshCredits();
  }, [modalOpen, cookies])

  useEffect(() => {
    if (newPassword2.length > 0 && newPassword1 != newPassword2) {
      setInternalError("Your passwords do not match");
    } else if (newPassword2.length > 0 && newPassword2.length < 8) {
      setInternalError("Your password must be at least 8 characters, and must contain at least: one number, one uppercase letter, and one lowercase letter.");
    }else {
      setInternalError("");
    }
  }, [newPassword1, newPassword2])

  const changePasswordFunction = async () => {
    setLoadingNewPassword(true);
    const resp = await changePassword(cookies['user-id'], oldPassword, newPassword1);
    if (resp.status == 200) {
      setSuccessMessage("Password successfully changed.");
      setOldPassword("");
      setNewPassword1("");
      setNewPassword2("");
    } else if (resp.status == 401) {
      setErrorMessage("Old password invalid. Please try again.");
    } else if (resp.status == 400) {
      setInternalError("Your password must be at least 8 characters, and must contain at least: one number, one uppercase letter, and one lowercase letter.");
    } else {
      setErrorMessage("We were unable to change your password at this time. Please try again in a minute.");
    }

    setLoadingNewPassword(false);
  }

  const logout = async () => {
    await logoutUser(cookies['user-id']);
    removeCookie('user-id');
    navigate('/login');
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="settingsModal" extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "40%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      position: "relative",
      display: "flex",
      alignItems: "left",
      justifyContent: "left",
    }}>
      <Text size={35} weight={600}>
        Settings
      </Text>
      <Divider />
      <Text size={25} weight={600}>
        Credits Available
      </Text>
      <div style={{ position: "relative"}}>
        <div style={{
          position: "absolute",
          left: "0px",
          width: `${((80 - credits) / 80) * 200}px`,
          height: '10px',
          borderRadius: '8px',
          backgroundColor: credits > 40 ? 'green' : credits > 5 ? 'yellow' : 'red',
          transition: 'width 0.5s ease-in-out',
          zIndex: 1001
        }} />
        <div style={{
          position: "absolute",
          left: "0px",
          top: "2.5px",
          width: `200px`,
          height: '5px',
          borderRadius: '5px',
          backgroundColor: 'black',
          transition: 'width 0.5s ease-in-out',
          zIndex: 1000
        }} />
      </div>

      <Text size={15}>{80 - credits} / 80 Credits Used</Text>
      { credits == 0 && (
        <>
          <Space px={10} />
          <Text size={15}>
            You are out of credits. Please contact matthew@coverflow.tech to receive more.
          </Text>
        </>
      )}
      <Text size={25} weight={600}>
        Change Password
      </Text>
      <Text size={15} weight={600}>
        Old Password
      </Text>
      <input
        type="password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.currentTarget.value)}
        style={{
          width: "calc(100% - 70px)",
          borderRadius: "8px",
          fontSize: "20px",
          padding: "10px",
          border: "1px solid #475569",
          outline: "none",
          fontFamily: "Assistant, sans-serif",
        }}
      />
      <Text size={15} weight={600}>
        New Password
      </Text>
      <input
        type="password"
        value={newPassword1}
        onChange={(e) => setNewPassword1(e.currentTarget.value)}
        style={{
          width: "calc(100% - 70px)",
          borderRadius: "8px",
          fontSize: "20px",
          padding: "10px",
          border: "1px solid #475569",
          outline: "none",
          fontFamily: "Assistant, sans-serif",
        }}
      />
      <Text size={15} weight={600}>
        Validate New Password
      </Text>
      <input
        type="password"
        value={newPassword2}
        onChange={(e) => setNewPassword2(e.currentTarget.value)}
        style={{
          width: "calc(100% - 70px)",
          borderRadius: "8px",
          fontSize: "20px",
          padding: "10px",
          border: "1px solid #475569",
          outline: "none",
          fontFamily: "Assistant, sans-serif",
        }}
      />
      { internalError != "" && (
        <Text color="red" size={20}>
          {internalError}
        </Text>
      )}
      { !loadingNewPassword && (
        <Button padding="15px" width="200px" color="grey" greyed={newPassword1 != newPassword2 || newPassword1.length < 3} onClick={changePasswordFunction}>
          <Text size={20} weight={600} color="white">
            Change Password
          </Text>
        </Button>
      )}
      { loadingNewPassword && (
        <div style={{ display: "flex", alignItems: "left"}}>
          <LoadingSpinner />
        </div>
      )}
      <Button padding="15px" width="200px" color="red" onClick={logout}>
        <Text size={20} weight={600} color="white">
          Logout
        </Text>
      </Button>
    </Modal>
  )
};

export default SettingsModal;
