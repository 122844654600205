import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";

import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import Container from "./components/Container";
import Document, { DocumentStatus, DocumentType } from "./components/Document";
import NewDocument from "./components/NewDocument";
import FileDrop from "./components/FileDrop";
import Button from "./components/base/Button";
import { secondsSince } from "./utils";

const Documents = ({ clientId, documents, retrieveDocuments, onDocumentClick, setErrorMessage }: { 
  clientId: string,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  onDocumentClick: (d: DocumentType) => void,
  setErrorMessage: (s: string) => void
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [applications, setApplications] = useState<DocumentType[]>([]);
  const [policies, setPolicies] = useState<DocumentType[]>([]);
  const [analyses, setAnalyses] = useState<DocumentType[]>([]);
  const [comparisons, setComparisons] = useState<DocumentType[]>([]);
  const [claims, setClaims] = useState<DocumentType[]>([]);
  const [comms, setComms] = useState<DocumentType[]>([]);

  useEffect(() => {
    let pols: DocumentType[] = [];
    let anls: DocumentType[] = [];
    let cmps: DocumentType[] = [];
    documents.forEach(d => {
      if (d.category == "policy") pols.push(d);
      else if (d.category == "analysis") anls.push(d);
      else if (d.category == "comparison") cmps.push(d);
    })

    setPolicies(pols.filter(d => !d.associatedDocumentId));
    setAnalyses(anls);
    setComparisons(cmps);
  }, [documents])

  return (
    <div style={{
      position: "fixed",
      height: "100%",
      top: "100px",
      left: "205px",
      padding: "53px 60px 0px 60px",
      overflowY: "scroll"
    }}>
      <Text size={40} color="#787878">
        Documents
      </Text>
      <Space px={25} />
      <Container key="policyContainer" minHeight="375px" title="Policies">
        <FileDrop documentType="policies" documentsLength={policies.length} retrieveDocuments={retrieveDocuments} category="policy" clientId={clientId} setErrorMessage={setErrorMessage}>
          <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: policies.length == 0 ? "center" : undefined }}>
            { policies.map(d => (
              <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments}onClick={onDocumentClick} />
            ))}
            <NewDocument documentsLength={policies.length} retrieveDocuments={retrieveDocuments} category="policy" clientId={clientId} />
          </div>
          </FileDrop>
      </Container>
      <Space px={25} />
      <Container key="analysisContainer" minHeight="375px" title="Policy Reports">
        <FileDrop documentType="analyses" documentsLength={analyses.length} retrieveDocuments={retrieveDocuments} category="analysis" clientId={clientId} setErrorMessage={setErrorMessage}>
          <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: analyses.length == 0 ? "center" : undefined }}>
            { analyses.length > 0 && analyses.map(d => (
              <Document key={d.instanceId + " report"} d={d} retrieveDocuments={retrieveDocuments} onClick={d.status === DocumentStatus.GENERATED ? onDocumentClick : undefined} />
            ))}
            { analyses.length == 0 && (
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                marginTop: "160px"
              }}>
                <Text size={30} color="#787878" align="center">
                  Click on a policy above to generate your first report.
                </Text>
              </div>
            )}
          </div>
        </FileDrop>
      </Container>
      <Space px={25} />
      <Container key="comparisonContainer" minHeight="375px" title="Policy Comparisons">
        <FileDrop documentType="comparisons" documentsLength={comparisons.length} retrieveDocuments={retrieveDocuments} category="comparison" clientId={clientId} setErrorMessage={setErrorMessage}>
          <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: comparisons.length == 0 ? "center" : undefined }}>
            { comparisons.length > 0 && comparisons.map(d => (
              <Document key={d.instanceId + " report"} d={d} retrieveDocuments={retrieveDocuments} onClick={d.status === DocumentStatus.GENERATED ? onDocumentClick : undefined} />
            ))}
            { comparisons.length == 0 && (
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                marginTop: "160px"
              }}>
                <Text size={30} color="#787878" align="center">
                  Click on a policy above to generate your first comparison.
                </Text>
              </div>
            )}
          </div>
        </FileDrop>
      </Container>
      <Space px={300} />
    </div>
  );
};

export default Documents;
