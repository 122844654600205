import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';
import { useGoogleLogin } from '@react-oauth/google';

import Title from "./base/Title";
import Text from "./base/Text";
import Login from "./Login";
import "./AppHeader.css";
import TextWithBorder from "./TextWithBorder";
import Spacer from "./base/Spacer";
import AlertsDropdown from "./AlertsDropdown";
import { AlertType, ClientType } from "../LandingPage";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '314px',  // Set your desired width here
    height: '45px',
    fontSize: '18px',
    fontFamily: 'Assistant',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Assistant', // Set your desired font family
    fontSize: '18px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Assistant', // Ensure consistency in selected value
    fontSize: '18px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: 'Assistant', // Ensure consistency in selected value
    fontSize: '18px',
  })
};

const clients = [
  {
    label: "Embargo Ltd.",
    value: "blueBottle"
  },
  {
    label: "Verve, Inc.",
    value: "verve"
  },
  {
    label: "Create a new client",
    value: "create",
    isFinal: true
  }
];

const AppHeader = ({ clientId, setClientId, clients, setCreateClientModalOpen, setSettingsModalOpen }: {
  clientId: string,
  setClientId: (s: string) => void,
  clients: ClientType[],
  setCreateClientModalOpen: (b: boolean) => void,
  setSettingsModalOpen: (b: boolean) => void
}) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [localClients, setLocalClients] = useState<any[]>([]);

  useEffect(() => {

    let updatedClients: any[] = clients.map(c => ({
      label: c.name,
      value: c.id
    }));

    updatedClients.push({
      label: "Create a new client",
      value: "new",
      isFinal: true
    });

    setLocalClients(updatedClients);

  }, [clients])

  const CustomSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div style={{ display: "flex", alignItems: "center"}}>
          <img src={process.env.PUBLIC_URL + "/assets/city-buildings.png"} alt="" style={{ width: "25px", height: "25px", marginRight: 10 }} />
          {props.data.label}
        </div>
      </components.SingleValue>
    );
  };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        { !!props.data.isFinal && <div style={{ height: "1px", width: "100%", backgroundColor: "#787878", marginTop: "-8px" }} /> }
        <div
          style={{ display: "flex", alignItems: "center", marginTop: !!props.data.isFinal ? "12px" : undefined}}
          onClick={ !!props.data.isFinal ? () => setCreateClientModalOpen(true) : undefined }
        >
          <img src={process.env.PUBLIC_URL + (!!props.data.isFinal ? "/assets/plus.png" : "/assets/city-buildings.png")} alt="" style={{ width: "25px", height: "25px", marginRight: 10 }} />
          {props.data.label}
        </div>
      </components.Option>
    );
  };

  /*

        <div style={{
        position: "fixed",
        top: "30px",
        left: "292px"
      }}>
        <Select
          value={localClients.find(c => c.value === clientId)}
          onChange={(option) => {
            if (option?.value === "new") {
              setCreateClientModalOpen(true);
            } else {
              setClientId(option?.value ?? "");
            }
          }}
          options={localClients}
          styles={customStyles}
          components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
          isSearchable={false}
        />
      </div>

      */

  return (
    <div style={{
      position: "fixed",
      width: "100%",
      height: "100px",
      top: 0,
      left: 0,
      zIndex: 5,
      backgroundColor: "#ffffff",
      boxShadow: "0 8px 6px -6px gray"
    }}>
      <img style={{
        position: "fixed",
        top: "30px",
        left: "30px",
        width: "37px",
        height: "48px"
      }} src={ process.env.PUBLIC_URL + "/assets/logo.png" } />
      <div style={{
        position: "fixed",
        top: "36px",
        left: "83px",
      }}>
        <Text size={30} weight={700} color="#354E7F">
          Coverflow
        </Text>
      </div>
      <img style={{
        position: "fixed",
        top: "28px",
        right: "34px",
        width: "45px",
        height: "45px",
        cursor: "pointer"
      }} src={ process.env.PUBLIC_URL + "/assets/settings.png" } onClick={() => setSettingsModalOpen(true)} />
    </div>
  )
};

export default AppHeader;
