import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Socket, io } from "socket.io-client";

import Documents from "./Documents";

import Sidebar from "./components/Sidebar";
import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import "./LandingPage.css";
import { retrieveReportRuns, retrieveExistingDocuments } from "./requests";
import { DocumentType, ReportRunType } from "./components/Document";
import PDFModal from "./components/PDFModal";
import PolicyComparisonModal from "./components/PolicyComparisonModal";
import SettingsModal from "./components/SettingsModal";

export type EmailType = {
  content: string;
  sender: string;
  recipient: string;
  time: string;
}

export type CallType = {
  summary: string;
  length: string;
  time: string;
}

export type ExtractedDataType = {
  extractedData: string;
  propertyInfo: string[];
  status: string;
}

export type AlertType = {
  id: string,
  alert: string,
  active: boolean,
  policyNumber: string,
  issuingCompany: string,
  sourceId: string,
  to: string,
  subject: string,
  body: string,
}

export type AlertTypeType = {
  id: string,
  sender: string,
  keywords: string[],
  alertType: string,
}

export type PropertyType = {
  id: string;
  address: string;
  sqft: string;
  imageUrls: string[];
  type: string; // "commercial" or "homeowner"
  extractedData: string;

  // homeowner fields
  price?: string;
  bedrooms?: string;
  baths?: string;
}

export type ClientType = {
  id: string;
  name: string;
}

export type DefinitionType = {
  id: string;
  name: string;
  definition: string;
  coverage_line: string;
}

const LandingPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [socket, setSocket] = useState<Socket | null>(null);
  const [sid, setSid] = useState<string>("");
  const [page, setPage] = useState<string>("documents");
  const [clientId, setClientId] = useState<string>("default_client");
  const [pdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [policyComparisonModalOpen, setPolicyComparisonModalOpen] = useState<boolean>(false);
  const [createClientModalOpen, setCreateClientModalOpen] = useState<boolean>(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);
  const [storageValue, setStorageValue] = useState(localStorage.getItem('authToken') || '');

  const [clients, setClients] = useState<ClientType[]>([]);
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [reportRuns, setReportRuns] = useState<ReportRunType[]>([]);
  const [document, setDocument] = useState<DocumentType | null>(null);
  const [secondDocument, setSecondDocument] = useState<DocumentType | null>(null);
  const [comparing, setComparing] = useState<boolean>(false);

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("ABOUT TO CREATE NEW SOCKET");
    const newSocket = io(process.env.REACT_APP_BE_URL || "", {
      query: {
        'auth-token': cookies['user-id'],
      },
      transports: ['websocket']
    });

    setSocket(newSocket);

    console.log("CREATED");

    return () => {
      newSocket.close();
    };
  }, [cookies]);

  useEffect(() => {
    socket?.on("connection", (data) => {
      console.log("RECEIVED CONNECTION");
      console.log(data);
      setSid(data.sid as string);
    });

    return () => {
      socket?.off("connection");
    };
  }, [socket]);

  useEffect(() => {
    socket?.on("refresh", (data) => {
      console.log("RECEIVED REFRESH");
      retrieveDocuments();
    });

    return () => {
      socket?.off("refresh");
    };
  }, [socket]);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < (window.innerHeight + 500));
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  useEffect(() => {
    const handleStorageChange = (event) => {
        if (event.key === 'authToken') {
            setStorageValue(event.newValue);
        }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
        window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Effect to update the cookie whenever the state changes
  useEffect(() => {
      if (storageValue && storageValue !== "") {
        setCookie('user-id', storageValue)
      }
  }, [storageValue]);

  useEffect(() => {
    if (!cookies['user-id']) {
      navigate("/login");
    }
  }, [cookies])

  useEffect(() => {
    if (clientId == "" && clients.length > 0) {
      setClientId(clients[0].id);
    }

  }, [clients])

  const retrieveDocuments = async () => {

    console.log("ABOUT TO RETRIEVE DOCS");

    const resp = await retrieveExistingDocuments(cookies['user-id'], clientId);
    const resp2 = await retrieveReportRuns(cookies['user-id'], clientId);

    const policies = resp.body["documents"].map(d => {
      return {
        id: d["document_id"],
        category: d["category"],
        instanceId: d["document_instance_id"],
        associatedDocumentId: d["associated_document_id"],
        lines: d["coverage_lines"],
        name: d["display_name"] ?? d["name"],
        link: d["s3_location"] ?? d["web_link"],
        status: d["status"],
        percentComplete: d["percent_complete"]
      }
    });

    const reports = resp2.body["report_runs"].map(d => {
      const reportType = d["instance_ids"].length > 1 ? "comparison" : "analysis"
      let newName = "";
      const name = policies.reduce(
        (prev, curr) => (d["instance_ids"].includes(curr.instanceId) ? curr.name + " / " : "") + prev, ""
      )
      if (reportType == "comparison") {
        newName = name.slice(0, name.length - 2) + " - Comparison";
      } else {
        newName = name.slice(0, name.length - 2) + " - Report";
      }
      
      return {
        id: d["document_id"],
        category: reportType,
        instanceId: d["run_id"],
        instanceIds: d["instance_ids"],
        name: d["display_name"] ?? newName,
        link: d["report_location"],
        status: d["status"],
        percentComplete: 100,
        reportInitiated: d["generation_time"]
      }
    });

    console.log("RETRIEVED DOCUMENTS");
    console.log("policies");
    console.log(policies);
    console.log("reports");
    console.log(reports);

    setDocuments([...policies, ...reports]);
  }

  const onDocumentClick = (d: DocumentType | null) => {
    setDocument(d);
    setPdfModalOpen(true);
  }

  useEffect(() => {
    retrieveDocuments();
  }, [clientId])

  /*

        { page === "definitions" && <Definitions clientId={clientId} definitions={definitions} retrieveDefinitions={retrieveDefs} setErrorMessage={setErrorMessage}/>}
              { page === "overview" && <Overview clientId={clientId} isPortrait={isPortrait} documents={documents} definitions={definitions} setExtractedData={setExtractedData} communications={communications} extractedData={extractedData} onDocumentClick={onDocumentClick} setNewDocumentModalOpen={setNewDocumentModalOpen} retrieveDocuments={retrieveDocuments} setErrorMessage={setErrorMessage} />}
        */

  return (
    <div className="landingPageBackground">
      <AppHeader clientId={clientId} setClientId={setClientId} clients={clients} setCreateClientModalOpen={setCreateClientModalOpen} setSettingsModalOpen={setSettingsModalOpen} />
      <Sidebar page={page} setPage={setPage} />
      { page === "documents" && <Documents clientId={clientId} documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setErrorMessage={setErrorMessage}/>}

      <BottomBar setPrivacyModalOpen={setPrivacyModalOpen} setTermsModalOpen={setTermsModalOpen} displayRight={ page != "overview"} />
      <PDFModal setModalOpen={setPdfModalOpen} modalOpen={pdfModalOpen} document={document} documents={documents} setDocument={setDocument} retrieveDocuments={retrieveDocuments} setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage} secondDocument={secondDocument} setSecondDocument={setSecondDocument} />
      <PolicyComparisonModal setModalOpen={setPolicyComparisonModalOpen} modalOpen={policyComparisonModalOpen} documents={documents} document={document} setSecondDocument={setSecondDocument} />
      <SettingsModal setModalOpen={setSettingsModalOpen} modalOpen={settingsModalOpen} setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage} />
      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <ToastContainer />
      <Analytics />
    </div>
  )
};

export default LandingPage;
