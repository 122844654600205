import React, { useState, useRef, useEffect, ReactNode } from "react";

import "./Button.css";

const Button = ({ onClick, color, width, height, maxHeight, border, greyed, padding, borderRadius, children }: {
  onClick?: () => void,
  color?: string,
  width?: string,
  height?: string,
  maxHeight?: string,
  border?: string,
  greyed?: boolean,
  padding?: string,
  borderRadius?: string,
  children: ReactNode
}) => {
  return (
    <div 
      className="button"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: color ?? "#354E7F",
        cursor: !greyed ? "pointer" : undefined,
        width: width ?? (padding ? undefined : "150px"),
        height: height ?? (padding ? undefined : "50px"),
        maxHeight: maxHeight ?? undefined,
        border: border ?? "none",
        padding: padding ?? undefined,
        opacity: greyed ? "0.5" : undefined,
        borderRadius: borderRadius ?? "8px"
      }}
      onClick={greyed ? undefined : onClick}
    >
      {children}
    </div>
  )
};

export default Button;
