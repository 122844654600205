import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
 // test update
import Sidebar from "./components/Sidebar";
import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";
import Button from "./components/base/Button";
import { login, validateCookie } from "./requests";


const Login = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<string>("overview");
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("")

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  const attemptLogin = async () => {
    setErrorMessage("");
    const resp = await login(username, password);
    if (resp.status == 200) {
      setCookie("user-id", resp.body["cookie"]);
      localStorage.setItem("refreshToken", resp.body["refresh_token"])
      navigate("/");
    } else if (resp.status == 401) {
      setErrorMessage("Your username or password are incorrect. Please ensure that you entered them correctly.");
    } else {
      setErrorMessage("Our backend is experiencing issues. Please try again in a couple of minutes.");
    }
  }

  return (
    <div style={{
      height: "100dvh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "40px",
        height: "min(70%, 400px)",
        width: "min(70%, 500px)",
        borderRadius: "16px",
        justifyContent: "center"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            width: "37px",
            height: "48px"
          }} src={ process.env.PUBLIC_URL + "/assets/logo.png" } />
          <Text size={30} weight={700} color="#354E7F">
            Coverflow
          </Text>
        </div>
        <Text size={40} weight={600} align="center">
          Enter your information to access Coverflow.
        </Text>
        <Text size={20} weight={600}>
          Username
        </Text>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
          style={{
            width: "calc(100% - 70px)",
            borderRadius: "8px",
            fontSize: "20px",
            padding: "10px",
            border: "1px solid #475569",
            outline: "none",
            fontFamily: "Assistant, sans-serif",
          }}
        />
        <Space px={20} />
        <Text size={20} weight={600}>
          Password
        </Text>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          style={{
            width: "calc(100% - 70px)",
            borderRadius: "8px",
            fontSize: "20px",
            padding: "10px",
            border: "1px solid #475569",
            outline: "none",
            fontFamily: "Assistant, sans-serif",
          }}
        />
        { errorMessage && (
          <Text color="red" size={20}>
            {errorMessage}
          </Text>
        )}
        <Space px={10} />
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center"
        }}>
          <Button padding="10px" width="100px" onClick={attemptLogin}>
            <Text size={20} color="white">
              Login
            </Text>
          </Button>
          <div style={{ cursor: "pointer" }} onClick={() => navigate("/create-account")}>
            <Text size={16} color="#354E7F">
              I have an access code.
            </Text>
          </div>
        </div>
      </div>


      <BottomBar setPrivacyModalOpen={setPrivacyModalOpen} setTermsModalOpen={setTermsModalOpen} displayRight={ page != "overview"} />
      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <ToastContainer />
      <Analytics />
    </div>
  )
};

export default Login;
