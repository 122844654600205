import React, { useState, useMemo, useRef, useEffect, ReactNode } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_TableInstance,
  type MRT_RowData
} from 'material-react-table';
import { css } from '@emotion/react';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentType } from "./Document";
import Button from "./base/Button";
import { addDocument, comparePolicyCoverage, retrieveDocumentOptions } from "../requests";
import { useCookies } from "react-cookie";
import AISearch from "./AISearch";
import LoadingSpinner from "./base/Spinner";

const PolicyComparisonModal = ({ setModalOpen, modalOpen, documents, document, setSecondDocument }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  documents: DocumentType[] | null,
  document: DocumentType | null,
  setSecondDocument: (d: DocumentType | null) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [status, setStatus] = useState<string>("selecting");

  // const [tableColumns, setTableColumns] = useState<string[]>([]);
  // const [tableRows, setTableRows] = useState<string[]>([]);
  // const [values, setValues] = useState<string[][]>([]);

  //const [table, setTable] = useState<any>(null);

  useEffect(() => {
    if (!modalOpen) {
      setStatus("selecting");
    }
  }, [modalOpen])

 

  const onDocumentClick = (d2: DocumentType) => {
    setSelectedDocument(d2);
    /*if (!!selectedDocuments?.find(d => d.instanceId == d2.instanceId)) {
      setSelectedDocuments(prev => prev.filter(d => d.instanceId != d2.instanceId));
    } else if (selectedDocuments.length < 5) {
      setSelectedDocuments(prev => [...prev, d2]);
    }*/
  }
  
  const onCompareSelect = async () => {
    setSecondDocument(selectedDocument);
    setModalOpen(false);
    /*setStatus("loading");
    const resp = await comparePolicyCoverage(cookies['user-id'], selectedDocuments.map(d => d.name));
    setTableColumns(["blank", ...resp.body['table_columns']]);
    //setTableColumns(["blank", "Policy 1", "Policy 2", "Policy 3"]);

    //const rows: string[] = resp.body['table_rows'];
    //setTableRows(["Row 1", "Row 2", "Row 3"]);
    setTableRows(resp.body['table_rows']);

    //let values: string[][] = resp.body['table_values'];
    /*setValues([
      ["Value 1", "Value 2", "Value 3"],
      ["Value 4", "Value 5", "Value 6"],
      ["Value 7", "Value 8", "Value 9"]
    ]);
    setValues(resp.body['table_values']);
    await new Promise(r => setTimeout(r, 2000));
    setStatus("comparison");*/
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="policyComparisonModal" extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "70%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll"
    }}>
      { status === "selecting" && (
        <>
          <Text size={35} weight={600}>
            Select a policy to compare
          </Text>
          <Divider />
          <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%" }}>
            { documents?.filter(d => d.category == "policy" && d.instanceId != document?.instanceId).map(d => (
              <div style={{ cursor: "pointer" }} onClick={() => onDocumentClick(d)}>
                <Document d={d} selecting selected={selectedDocument?.instanceId == d.instanceId} />
              </div>
            ))}
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setModalOpen(false)}>
              <Text size={20} weight={600} color="#000000">
                Cancel
              </Text>
            </Button>
            <Button greyed={!selectedDocument} onClick={onCompareSelect}>
              <Text size={16} weight={600} color="white">
                Select Document
              </Text>
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
};

export default PolicyComparisonModal;

/*

function transpose(array: string[][]): string[][] {
  return Array.from({ length: array[0].length }, (_, colIndex) =>
      array.map(row => row[colIndex])
  );
}

{ status == "loading" && (
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}>
          <LoadingSpinner />
          <Text size={20} color="#787878">
            Your policy comparison is generating. This process usually takes 10 seconds.
          </Text>
        </div>
      )}
      { status == "comparison" && (
        <>
          <div style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
            overflowY: "scroll",
          }}>
            <Text size={40} weight={600}>
              Policy Comparison
            </Text>
            <MaterialReactTable table={table} />
            <Button color="#ffffff" border="1px solid black" padding="10px" onClick={() => setStatus("selecting")}>
              <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center"
              }}>
                <img src={process.env.PUBLIC_URL + "/assets/ai.png"} style={{ width: "25px", height: "25px" }} />
                <Text size={20}>
                  Generate again with new policies
                </Text>
              </div>
            </Button>
          </div>
        </>
      )}

       const columns = useMemo(() => {
    return tableColumns.map(c => ({
      accessorKey: c.toLowerCase().replaceAll(" ", ""),
      header: c == "blank" ? " " : c,
      size: 200
    }));
  }, [tableColumns]);

        const data: MRT_RowData[] = useMemo(() => {
    values.unshift(tableRows);
    console.log("Unshifted");
    console.log(values);
    let newValues = transpose(values);
    console.log("Transposed");
    console.log(newValues);
    let newValuesObject = newValues.map(r => {
      let obj = {};
      for (let i = 0; i < tableColumns.length; i++) {
        console.log("column");
        console.log(tableColumns[i]);
        obj[tableColumns[i].toLowerCase().replaceAll(" ", "")] = r[i];
      }
      return obj;
    });
    console.log("object");
    console.log(newValuesObject);
    return newValuesObject;
  }, [values, tableRows, tableColumns]);

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enablePagination: false, // Disable pagination
    muiTableContainerProps: {
      style: {
        maxHeight: '100%', // Set a maximum height for the table container
        overflowY: 'auto'    // Make the table container scrollable
      }
    },
  });

*/
