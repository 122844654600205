import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';

import Sidebar from "./components/Sidebar";
import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";
import Button from "./components/base/Button";
import { createAccount, login, validateAccessCode, validateCookie } from "./requests";


const CreateAccount = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<string>("accessCode");
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);

  const [accessCode, setAccessCode] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [secondPassword, setSecondPassword] = useState<string>("");
  const [internalError, setInternalError] = useState<string>("");

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  useEffect(() => {
    if (secondPassword.length > 0 && password != secondPassword) {
      setInternalError("Your passwords do not match");
    } else if (secondPassword.length > 0 && secondPassword.length < 8) {
      setInternalError("Your password must be at least 8 characters, and must contain at least: one number, one uppercase letter, and one lowercase letter.");
    }else {
      setInternalError("");
    }
  }, [secondPassword, password])

  async function validateCode() {
    setPage("loading");
    const isValid = await validateAccessCode(accessCode);
    if (isValid.body["valid"]) {
      setSuccessMessage("Your access code was approved.");
      setPage("createAccount");
    } else {
      setErrorMessage("Your access code was invalid.");
      setPage("accessCode");
    }
  }

  async function createUserAccount() {
    setPage("loading");
    const resp = await createAccount(accessCode, username, password);
    if (resp.status == 200) {
      setCookie("user-id", resp.body["cookie"]);
      localStorage.setItem("refreshToken", resp.body["refresh_token"])
      navigate("/");
    } else if (resp.status == 400) {
      setInternalError("Your password must be at least 8 characters, and must contain at least: one number, one uppercase letter, and one lowercase letter.");
      setPage("createAccount");
    } else {
      setErrorMessage("We encountered an error creating your account. Please try again.");
      setPage("createAccount");
    }
  }

  return (
    <div style={{
      height: "100dvh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "40px",
        height: "min(70%, 400px)",
        width: "min(70%, 500px)",
        borderRadius: "16px",
        justifyContent: "center"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            width: "37px",
            height: "48px"
          }} src={ process.env.PUBLIC_URL + "/assets/logo.png" } />
          <Text size={30} weight={700} color="#354E7F">
            Coverflow
          </Text>
        </div>
        { page == "accessCode" && (
          <>
            <Text size={40} weight={600} align="center">
              Validate your access code below
            </Text>
            <Text size={20} weight={600}>
              Access Code
            </Text>
            <input
              type="text"
              value={accessCode}
              onChange={(e) => setAccessCode(e.currentTarget.value)}
              style={{
                width: "calc(100% - 70px)",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "10px",
                border: "1px solid #475569",
                outline: "none",
                fontFamily: "Assistant, sans-serif",
              }}
            />
            <Button padding="15px" width="150px" greyed={accessCode.length == 0} onClick={validateCode}>
              <Text size={20} color="white">
                Validate Code
              </Text>
            </Button>
          </>
        )}
        { page == "loading" && (
          <LoadingSpinner />
        )}
        { page == "createAccount" && (
          <>
            <Text size={40} weight={600} align="center">
              Create an account below.
            </Text>
            <Text size={20} weight={600}>
              Username
            </Text>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
              style={{
                width: "calc(100% - 70px)",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "10px",
                border: "1px solid #475569",
                outline: "none",
                fontFamily: "Assistant, sans-serif",
              }}
            />
            <Text size={20} weight={600}>
              Password
            </Text>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              style={{
                width: "calc(100% - 70px)",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "10px",
                border: "1px solid #475569",
                outline: "none",
                fontFamily: "Assistant, sans-serif",
              }}
            />
            <Text size={20} weight={600}>
              Validate Password
            </Text>
            <input
              type="password"
              value={secondPassword}
              onChange={(e) => setSecondPassword(e.currentTarget.value)}
              style={{
                width: "calc(100% - 70px)",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "10px",
                border: "1px solid #475569",
                outline: "none",
                fontFamily: "Assistant, sans-serif",
              }}
            />
            { internalError != "" && (
              <Text color="red" size={20}>
                {internalError}
              </Text>
            )}
            <Button padding="15px" width="150px" onClick={createUserAccount} greyed={username.length == 0 || password.length < 8 || password != secondPassword}>
              <Text size={20} color="white">
                Create Account
              </Text>
            </Button>
          </>
        )}
        <Space px={10} />
      </div>
      <BottomBar setPrivacyModalOpen={setPrivacyModalOpen} setTermsModalOpen={setTermsModalOpen} displayRight={ page != "overview"} />
      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <ToastContainer />
      <Analytics />
    </div>
  )
};

export default CreateAccount;
